import KitchenConfig from "./products/kitchenaid";

// Use this form if there are no custom elements - empty div
import Null from "./products/null";

const ProductList = {
  1: {
    type: "3D",
    imageURL:
      "https://solutions-engineering.s3.amazonaws.com/quickdemo/medical-devices/operating.png",
    threekit: "ba8596d9-1724-4402-8fb6-52125f6e2ff2",
    name: "Operating Table",
    scene: "",
    initialConfig: {},
    showAR: true,
    showConfig: false,
    brand: "",
    form: Null,
  },
  2: {
    type: "3D",
    imageURL:
      "https://solutions-engineering.s3.amazonaws.com/quickdemo/medical-devices/mri.png",
    threekit: "8adb3af8-0737-405a-913e-497fcf8c606f",
    name: "MRI",
    scene: "",
    initialConfig: {},
    showAR: true,
    showConfig: false,
    brand: "",
    form: Null,
  },
  3: {
    type: "3D",
    imageURL:
      "https://solutions-engineering.s3.amazonaws.com/quickdemo/medical-devices/anes.png",
    threekit: "e83ad948-2e23-4de0-b1f0-5caf9f1e2652",
    name: "Anesthesia Machine",
    scene: "",
    initialConfig: {},
    showAR: true,
    showConfig: false,
    brand: "",
    form: Null,
  }
};
export { ProductList };
